import React from "react";

export const VizParole = () => {

    return (
        <div>
            <iframe src="https://openprocessing.org/sketch/1639241/embed/?plusEmbedHash=NDNkNzYwOGZjOWEyOWU5MzgwMzA5MjE3OGU3OWM4YmM5Y2VmNzJjYzg5OTlmMWMwNjVmMTAxMmYyZWY1ZjRlMzhjMTAxZDc4Zjk4NmI3MmY0NTVmNDM5ODRjZjJlZGY1MTAzY2Q1YmZhMmQ4YzJhMGQ4ZTZmMzMzZTFiMmU4MGFNTEwyVTdna1N6R29rY1NoaFhRVWpXdGZMak0zRTF6cU5tdnp2MEFYZlVRZmdQeHRRWHV2SGVWeEFzUHB4eStMbHhndDRWcWdseFFMNTFiZFB2eFJaUT09&plusEmbedFullscreen=true" width="100%" style={{border:'none', height:'100vh'}}></iframe>
        </div>
    )
}
