import React from "react";
import {Box} from "@mui/material";
import header from '../assets/images/logo.png'

export const Header = () => {
  return(
      <Box sx={{width:'100%', textAlign:'center'}}>
          <img src={header} style={{width:'90%', maxWidth:'40rem', margin:'auto', paddingTop:'2rem'}}/>
          <hr style={{borderColor:'#000', borderWidth:'1px', width:'90%', maxWidth:'80rem'}}/>
      </Box>
  )
}
