import React from "react";
import {Box, Container, Typography} from "@mui/material";
import {Header} from "../components/Header";

export const Privacy = () => {
    return (
        <Box>
            <Header/>
            <Container sx={{mt: 4}}>
                <Typography variant={"body1"}>
                    La presente informativa è resa ai sensi del Regolamento 2016/679 ed afferisce alla sua iscrizione
                    all’evento del 24 settembre p.v. “Impatto per la comunità: incontro con gli stakeholder”.
                    <br/>
                    Titolare del trattamento è la Compagnia di San Paolo, con sede in Torino, Corso Vittorio Emanuele II
                    N.
                    75
                    <br/>
                    Il conferimento dei dati è necessario al fine di poter organizzare al meglio l’evento e permettervi
                    di
                    prenderne parte. La Compagnia di San Paolo si impegna in ogni caso a garantire la sicurezza e la
                    riservatezza dei dati raccolti, secondo la normativa vigente. I dati verranno conservati per l’invio
                    di
                    comunicazioni relative ad eventi ed opportunità curate dalla nostra Fondazione o dai suoi Enti
                    Strumentali per un periodo massimo di 2 anni. Potrà in ogni caso essere richiesta in ogni momento la
                    cancellazione.
                    <br/>
                    Durante l’evento verranno altresì effettuate riprese video e fotografiche, che potranno essere
                    diffuse
                    attraverso i canali social ed istituzionali della Compagnia di San Paolo, al fine di dare evidenza
                    all’evento. Qualora non vogliate essere ripresi, vi preghiamo di darcene comunicazione utilizzando
                    la
                    sezione “Eventuali note o informazioni aggiuntive” del presente form.
                    <br/>
                    Per eventuali richieste e informazioni è possibile far riferimento all’indirizzo mail: <a
                    href="mailto:privacy@compagniadisanpaolo.it">privacy@compagniadisanpaolo.it</a>
                    <br/>
                    [eventuali informazioni tecniche a cura di Plesh]
                </Typography>
            </Container>
        </Box>
    )
}
