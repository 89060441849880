import React from "react";

export const DataViz = () => {

    return (
        <div>
            <iframe src="https://openprocessing.org/sketch/1646454/embed/?plusEmbedHash=YWU5MWFiMDZjZjI4MDM3YzViOTJmZDkzYTJiMWEwOWM5YWNkZTNkNjYyOTNiYWRlNmFiNDQ5OTRlNTY1ZmU5YWY0OTMzMzQyMDllMDMzY2NhOTFkM2IxYjkzNjcyODA3YjcyOTE3ZmVlNzNiOWEwNDk4ZjUyODY5NjI2YjU0Y2VCSjBhMHB0UzZzNjVJRkU3bngyY0dVV0dYcnI0VkNic2dZbkh5LzFCeVpMdjlOTmJJak9DZFdMUDlzZ05iMW5PQWlEL1RVaFRzSWZpWUdEUyt5a1B6UT09&plusEmbedFullscreen=true" width="100%" style={{border:'none', height:'100vh'}}></iframe>
        </div>
    )
}
