import {createTheme} from "@mui/material";
import '../App.css';

export const cpsTheme = createTheme({
    typography:{
        fontFamily: 'Gelasio'
    },
    palette:{
        primary:{
            main:'#016634'
        }
    }
})
