import './App.css';
import {Form} from "./pages/Form";
import {Route,Routes} from "react-router-dom";
import {Privacy} from "./pages/Privacy";
import {DataViz} from "./pages/DataViz";
import {VizParole} from "./pages/VizParole";

function App() {
  return (
    <div>
        <Routes>
          <Route path={'/'} element={<Form/>}/>
          <Route path={'/privacy'} element={<Privacy/>}/>
          <Route path={'/data-viz/frasi'} element={<DataViz/>}/>
            <Route path={'/data-viz/parole'} element={<VizParole/>}/>
        </Routes>
    </div>
  );
}

export default App;
