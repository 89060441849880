import React, {useState} from "react";
import {
    Backdrop,
    Box,
    Button, Checkbox, CircularProgress, Collapse,
    Container, FormControl, FormControlLabel, FormLabel,
    Grid, Radio, RadioGroup, Snackbar,
    TextareaAutosize,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
import {Link} from "react-router-dom";
import {Header} from "../components/Header";

const campi = [
    {id: 'ente', placeholder: 'Ente/Organizzazione', required: true, type: 'text', oninvalid: 'Campo obbligatorio'},
    {id: 'nome', placeholder: 'Nome', required: true, type: 'text', oninvalid: 'Campo obbligatorio'},
    {id: 'cognome', placeholder: 'Cognome', required: true, type: 'text', oninvalid: 'Campo obbligatorio'},
    {
        id: 'ruolo',
        placeholder: 'Ruolo all’interno dell’ente/organizzazione',
        required: true,
        type: 'text',
        oninvalid: 'Campo obbligatorio'
    },
    {
        id: 'email',
        placeholder: 'Indirizzo e-mail',
        required: true,
        type: 'email',
        oninvalid: "L'email inserita non è valida"
    },
    {
        id: 'note',
        placeholder: 'Eventuali note o informazioni aggiuntive',
        required: false,
        type: 'text',
        oninvalid: 'Campo obbligatorio'
    },
    {id: 'telefono', placeholder: 'Numero di telefono', required: true, type: 'text', oninvalid: 'Campo obbligatorio'},
]

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Form = () => {
    const [dati, setDati] = useState({
        nome: "",
        cognome: "",
        ente: "",
        ruolo: "",
        email: "",
        telefono: "",
        note: ""
    })

    const [delego, setDelego] = useState('')
    const [privacy, setPrivacy] = useState(false)

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')

    const sendData = async (e) => {
        e.preventDefault()
        if(!privacy){
            setMessage("Per favore accetti la privacy policy")
            setError(true)
        } else {
            setSending(true)
            await axios.post('https://csp-22-api-55ewkvud7q-ey.a.run.app/register', {...dati, delego: delego})
                .then(() => setSuccess(true))
                .catch((error) => {
                    setMessage(error.response.data.error)
                    setError(true)
                })
            setSending(false)
        }
    }

    const handleChange = (e,field) => {
        setDati((prevState) => ({...prevState, [field]:e.target.value}))
    }

    return (
        <Box>
            <Header/>
            <Container sx={{mb: 3}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={sending}
                    onClick={()=>setSending(false)}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={error} autoHideDuration={4000} onClose={() => setError(false)}>
                    <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%', fontWeight:'bold' }}>
                        {message}
                    </Alert>
                </Snackbar>
                <Box sx={{my: 4}}>
                    <Typography variant={"h4"} fontWeight={"bold"} color={'white'} sx={{mb: 0.3}}><span
                        style={{background: "#016634", padding: '0 0.4rem'}}>Iscrizione all'evento</span></Typography>
                    <Typography variant={matches ? "h5" : "h6"} fontWeight={"bold"} color={'white'}>
                    <span style={{background: "#016634", padding: '0 0.4rem'}}>
                        Impatto per la comunità:{!matches && <br/>}&nbsp;incontro con gli stakeholder
                    </span>
                    </Typography>
                    <Typography variant={matches ? "h6" : "subtitle1"}>24 settembre 2022, Collegio Carlo Alberto Torino</Typography>
                </Box>
                <form onSubmit={sendData}>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Partecipazione (seleziona una scelta)</FormLabel>
                        <RadioGroup
                            row
                            defaultValue=""
                            name="radio-buttons-group"
                            onChange={(e) => setDelego(e.target.value)}
                        >
                            <FormControlLabel value={'false'} control={<Radio />} label="Partecipo di persona" />
                            <FormControlLabel value={'true'} control={<Radio />} label="Delego" />
                        </RadioGroup>
                    </FormControl>
                    <Collapse in={delego!==''}>
                        <Typography variant={"h6"}>{delego==='true' ? 'Inserisca qui sotto i dati della persona delegata': 'Inserisca qui sotto i suoi dati:'}</Typography>
                        {delego!=='' && <Grid container spacing={4} justifyContent={"space-between"}>
                            {Object.keys(dati).map((d, i) =>
                                <Grid item xs={12} md={d === 'note' ? 12 : 6} key={i}>
                                    {d !== 'note' ?
                                        <TextField required={campi.find(c => c.id === d).required} variant={"standard"}
                                                   sx={{width: '100%'}}
                                                   type={campi.find(c => c.id === d).type}
                                                   label={campi.find(c => c.id === d).placeholder}
                                                   onChange={(e) => handleChange(e, d)}
                                                   onInvalid={e => e.target.setCustomValidity(campi.find(c => c.id === d).oninvalid)}
                                                   onInput={e => e.target.setCustomValidity('')}></TextField>
                                        :
                                        <Box sx={{width: '100%'}}>
                                            <Typography>{campi.find(c => c.id === d).placeholder}</Typography>
                                            <TextareaAutosize
                                                onChange={(e) => handleChange(e, d)}
                                                style={{fontFamily: 'Gelasio', width: '100%', resize: 'none', padding: 0}}
                                                minRows={6}/>
                                        </Box>
                                    }
                                </Grid>
                            )}
                        </Grid>}
                        <Checkbox sx={{ml:-1.4}} checked={privacy} onChange={(e) => setPrivacy(e.target.checked)} />Accetto la <Link to={'/privacy'} target={'_blank'}>privacy policy</Link>

                        <Box sx={{width: '100%', textAlign: 'center', mt: 2}}>
                            {success ?
                                <Typography variant={"subtitle1"} color={'#016634'} fontWeight={'bold'}>L'iscrizione è stata
                                    completata con successo</Typography> :
                                <Button sx={{borderRadius: 0}} type={"submit"} size={"large"}
                                        variant={"contained"}>Invia</Button>}
                        </Box>
                    </Collapse>
                </form>
            </Container>
        </Box>

    )
}
